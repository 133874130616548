
import { defineComponent, ref } from 'vue';
import DefaultTemplate from '@/components/global-config/geotab/DefaultTemplate.vue';
import DefaultWorkflows from '@/components/global-config/geotab/DefaultWorkflows.vue';

export default defineComponent({
  components: {
    DefaultTemplate,
    DefaultWorkflows,
  },
  setup() {
    const template = ref<number>(0);
    return { template };
  },
});
